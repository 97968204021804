<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :src="drawerImage ? image : ''"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <template v-if="drawerImage" #img="props">
      <v-img :key="image" :gradient="gradient" v-bind="props" />
    </template>
    <div class="px-2">
      <default-drawer-header class="pt-6 pb-9" />

      <default-list :items="items" />
    </div>
    <template #append>
      <v-list-item @click="mini = !mini">
        <v-list-item-avatar color="primary">
          <v-icon v-if="mini" dark>
            mdi-chevron-right
          </v-icon>
          <v-icon v-else dark>
            mdi-chevron-left
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $t("daralt") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";
export default {
  name: "DefaultDrawer",
  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./widgets/DrawerHeader"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List"
      )
  },
  computed: {
    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
    mini: sync("app/mini")
  }
};
</script>

<style lang="sass">

.v-navigation-drawer__content
  height: 100%
  overflow-y: auto
  overflow-x: hidden !important
  &::-webkit-scrollbar
    width: 5px
  &::-webkit-scrollbar-thumb
    background: #9c27b0
    border-radius: 20px
#default-drawer
  .v-list-item
    margin-bottom: 16px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
